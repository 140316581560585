import React, { useContext } from 'react';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import { Box, Flex, Text, Image } from '@chakra-ui/react';
import { LocaleContext } from '@helpers/LocaleProvider';
import FooterNav from '@components/molecules/FooterNav';
import SocialNav from '@components/molecules/SocialNav';
import Wrapper from '@components/atoms/Wrapper';
import logo from '@assets/images/milka-logo.svg';
import LazyLoad from '../atoms/LazyLoad';

const Footer = ({ footerMenu }) => {
  const {
    site: {
      siteMetadata: { title: siteTitle }
    },
    allStrapiFooter
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
      allStrapiFooter {
        nodes {
          locale
          copyright
          socialLinks {
            title
            youtubeLink
            instagramLink
            facebookLink
          }
        }
      }
    }
  `);

  /*
   * Sets the current locale .
   */
  const { locale } = useContext(LocaleContext);

  if (!allStrapiFooter) {
    return null;
  }

  /*
   * Find matching locale for copyright content.
   */
  const strapiFooter = allStrapiFooter.nodes.find(
    (node) => node.locale === locale
  );

  const { copyright } = strapiFooter;
  const { socialLinks } = strapiFooter;

  return (
    <Box as="footer" color="white" pt={20}>
      <LazyLoad>
        <Wrapper maxWidth="none">
          <Flex
            direction={{
              base: `column`,
              xl: `row-reverse`
            }}
            alignItems={{ lg: `flex-start` }}
            justifyContent={{ md: `space-between` }}
            py="2.375rem"
            px={{ lg: `3.75rem` }}
            borderTop="1px"
            borderColor="milka.primary.dark-purple">
            <Flex
              width={{ lg: `100%`, xl: `auto` }}
              direction={{ base: `column`, lg: `row` }}
              justifyContent={{ lg: `space-between` }}
              gap={{ lg: `6.6rem` }}>
              <FooterNav locale={locale} footerMenu={footerMenu} />
              <SocialNav
                locale={locale}
                socialLinks={socialLinks}
                mt={{ base: 6, lg: 0 }}
              />
            </Flex>
            <Box mt={{ base: 10, xl: 0 }} mr={{ md: 10 }}>
              <Flex
                as={GatsbyLink}
                to={`/${locale}`}
                position="relative"
                width={{ base: `5.676rem`, md: `8.375rem` }}
                mx={{ base: `auto`, lg: 0 }}
                mb={{ base: 6, lg: 2 }}
                zIndex="2"
                transition="transform .5s ease-in-out"
                _hover={{
                  transform: `scale(1.1)`
                }}>
                <Image src={logo} alt={siteTitle} />
              </Flex>
              {copyright && (
                <Text
                  fontSize="sm"
                  mb="0"
                  textAlign={{ base: `center`, lg: `left` }}>
                  {copyright}
                </Text>
              )}
            </Box>
          </Flex>
        </Wrapper>
      </LazyLoad>
    </Box>
  );
};

export default Footer;
