import React, { Suspense, useEffect, useState } from 'react';

const SuspenseHelper = ({ fallback, children }) => {
  const isBrowser = typeof window !== `undefined`;
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={fallback}>
      {!isBrowser || !isMounted ? fallback : children}
    </Suspense>
  );
};

export default SuspenseHelper;
