import React from 'react';
import { Box } from '@chakra-ui/react';

const Wrapper = ({ children, ...overrides }) => {
  return (
    <Box
      px={{ base: 6, md: 14 }}
      mx="auto"
      width="100%"
      maxWidth="90rem"
      {...overrides}>
      {children}
    </Box>
  );
};

export default Wrapper;
