import React, { useContext } from 'react';
import {
  Flex,
  Box,
  AspectRatio,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  useDisclosure,
  Show,
  Hide,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { LocaleContext } from '@helpers/LocaleProvider';
import LocaleList from '@components/molecules/LocaleList';

const LocaleOptions = () => {
  const { locale } = useContext(LocaleContext);
  const flags = require.context(`../../assets/images/flags`, true, /\.svg$/);
  const { isOpen, onToggle, onClose } = useDisclosure();
  return (
    <Flex width="100%">
      <Show below="md">
        <Accordion allowMultiple width="100%">
          <AccordionItem border="none" width="100%">
            <AccordionButton
              justifyContent="center"
              py={3}
              _expanded={{ bg: `milka.primary.dark-purple` }}>
              <AspectRatio
                boxSize="1.5rem"
                ratio="1"
                display="inline-block"
                borderRadius="full"
                border="1px solid white"
                verticalAlign="middle"
                mr={2}
                mt="-0.0625rem"
                overflow="hidden">
                <Image src={flags(`./${locale}.svg`).default} alt={locale} />
              </AspectRatio>
              <Box
                as="span"
                fontFamily="heading"
                fontSize="sm"
                textTransform="uppercase">
                ({locale})
              </Box>
              <ChevronDownIcon
                boxSize={5}
                color="milka.primary.light-blue"
                ml={1}
              />
            </AccordionButton>
            <AccordionPanel p={6} bg="white">
              <LocaleList />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Show>
      <Hide below="md">
        <Popover isOpen={isOpen} onClose={onClose}>
          <Box
            as="button"
            display="flex"
            alignItems="center"
            m="0 0 0 auto"
            onClick={onToggle}>
            <PopoverTrigger>
              <AspectRatio
                boxSize="1.5rem"
                ratio="1"
                display="inline-block"
                borderRadius="full"
                border="1px solid white"
                verticalAlign="middle"
                mr={2}
                mt="-0.0625rem"
                overflow="hidden">
                <Image src={flags(`./${locale}.svg`).default} alt={locale} />
              </AspectRatio>
            </PopoverTrigger>
            <Box
              as="span"
              fontFamily="heading"
              fontSize="sm"
              textTransform="uppercase">
              ({locale})
            </Box>
            <ChevronDownIcon
              boxSize={5}
              color={
                isOpen ? `milka.primary.dark-purple` : `milka.shades.white`
              }
              ml={1}
            />
          </Box>
          <PopoverContent
            width="100%"
            minWidth={{ md: `12.125rem` }}
            borderRadius={{ md: 0 }}
            pt={6}
            px={6}
            mt={2}>
            <PopoverArrow />
            <PopoverBody
              pt={0}
              px={0}
              pb={6}
              maxHeight="9.6875rem"
              overflow="auto">
              <LocaleList />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Hide>
    </Flex>
  );
};

export default LocaleOptions;
