export const colors = {
  milka: {
    primary: {
      lilac: `#7D69AC`,
      'lilac-muted': `#7D69AC1A`,
      'dark-purple': `#3B2774`,
      'light-blue': `#8AD1EF`,
      'dark-blue': `#0056A5`
    },
    secondary: {
      vanilla: `#FFFBCD`,
      hazelnut: `#91B44C`,
      caramel: `#E39B30`,
      'strawberry-cheesecake': `#DA8083`,
      'butter-cookie': `#F8D93E`,
      praline: `#4A6638`,
      'caramel-crunch': `#E39B30`,
      'almond-caramel': `#F5DAB3`
    },
    shades: {
      black: `#000000`,
      white: `#FFFFFF`
    }
  }
};
