import React from 'react';
import { Icon } from '@chakra-ui/react';

const BurgerDefaultIcon = (rest) => (
  <Icon width="18" height="14" viewBox="0 0 18 14" fill="none" {...rest}>
    <line
      x1="0.75"
      y1="1.25"
      x2="17.25"
      y2="1.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="0.75"
      y1="7.25"
      x2="15.09"
      y2="7.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="0.75"
      y1="13.25"
      x2="17.25"
      y2="13.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </Icon>
);

export default BurgerDefaultIcon;
