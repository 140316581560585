import React, { lazy } from 'react';
import SuspenseLoader from '@components/atoms/SuspenseLoader';
import SuspenseHelper from '@components/atoms/SuspenseHelper';
import { Heading } from '@chakra-ui/react';
import Wrapper from '@components/atoms/Wrapper';

const CookieBotBlock = lazy(
  () => import(`@components/atoms/CookieBotDeclaration`)
  // eslint-disable-next-line function-paren-newline
);

const CookiePolicy = ({ data }) => {
  const { title, loadingText } = data;
  return (
    <Wrapper>
      {title && <Heading>{title}</Heading>}
      <SuspenseHelper fallback={<SuspenseLoader loadingText={loadingText} />}>
        <CookieBotBlock id={process.env.GATSBY_COOKIEBOT_ID} />
      </SuspenseHelper>
    </Wrapper>
  );
};

export default CookiePolicy;
