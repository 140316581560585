import React from 'react';
import { Box } from '@chakra-ui/react';
import CenteredTextBlock from './CenteredTextBlock';

const StandardHero = ({ data }) => {
  return (
    <Box py={{ base: 0, md: 6 }}>
      <CenteredTextBlock data={data} headingType="h1" isHero />
    </Box>
  );
};

export default StandardHero;
