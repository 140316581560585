export const TABLE_CELL_HEADER_STATE = {
  BOTH: 3,
  COLUMN: 2,
  NO_STATUS: 0,
  ROW: 1
};

export const LIST_TYPE = {
  BULLET: `bullet`,
  NUMBER: `number`
};

export const HEADING_SIZES = {
  h1: `3xl`,
  h2: `2xl`,
  h3: `xl`,
  h4: `lg`,
  h5: `md`,
  h6: `sm`
};

export const applyInlineFormat = (format, text) => {
  switch (format) {
    case 1:
      return `<strong>${text}</strong>`;
    case 2:
      return `<em>${text}</em>`;
    case 3:
      return `<strong><em>${text}</em></strong>`;
    case 4:
      return `<s>${text}</s>`;
    case 8:
      return `<u>${text}</u>`;
    case 96:
      return `<sub>${text}</sub>`;
    case 64:
      return `<sup>${text}</sup>`;
    default:
      return text || ``;
  }
};

export const buildNode = ({
  _parent = null,
  _parentIndex = 0,
  index,
  type,
  children = [],
  ...rest
}) => {
  const id = `${_parentIndex}-${index}-${type}-${Date.now()}`;

  const thisNode = {
    id,
    _parent,
    _parentIndex,
    type,
    index,
    descendants: [],
    ...rest
  };

  const mappedChildren =
    children.map((child, childIndex) => {
      return buildNode({
        _parent: id,
        _parentIndex: index,
        index: childIndex,
        ...child
      });
    }) || [];

  return mappedChildren.length > 0
    ? { ...thisNode, descendants: mappedChildren }
    : thisNode;
};
