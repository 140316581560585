import React from 'react';
import Button from '@components/atoms/Button';

const PaginationDisc = ({ isActive = false, onClick = () => {} }) => (
  <Button
    size="ellipsed"
    bg={isActive ? `secondary.light-grey` : `primary.froneri-blue`}
    textColor="primary.froneri-blue"
    boxShadow="inset 0px 0px 0px 2px"
    _hover={
      isActive
        ? {
            cursor: `default`,
            textColor: `primary.froneri-blue`,
            bg: `secondary.light-grey`
          }
        : {
            textColor: `primary.froneri-blue`,
            bg: `secondary.light-grey`
          }
    }
    onClick={onClick}
  />
);

export default PaginationDisc;
