import React from 'react';
import { AspectRatio, Flex } from '@chakra-ui/react';
import Image from '@components/atoms/Image';

const ImageGridBlock = ({ data }) => {
  return (
    <>
      {data?.imageLeft && data?.imageRight && (
        <Flex
          w="100%"
          px="4"
          gap="4"
          my="4"
          direction={{ base: `column`, md: `row` }}>
          <AspectRatio
            ratio={1}
            w={{ base: `100%`, md: `50%` }}
            mb={{ base: 0, md: `20` }}>
            <Image image={data?.imageLeft} />
          </AspectRatio>
          <AspectRatio
            ratio={1}
            w={{ base: `100%`, md: `50%` }}
            mt={{ base: 0, md: `20` }}>
            <Image image={data?.imageRight} />
          </AspectRatio>
        </Flex>
      )}
    </>
  );
};

export default ImageGridBlock;
