import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Fonts } from '@components';
import { theme } from '@styles/theme';
import LocaleProvider from '@helpers/LocaleProvider';

export const wrapPageElement = ({ element, props }) => {
  const {
    pageContext: { locale = `en`, locales = [] }
  } = props;

  // If current locale exists in here
  const altFontLocales = [
    `hu-HU`,
    `cs`,
    `pl`,
    `et-EE`,
    `lv-LV`,
    `lt-LT`,
    `ro`,
    `sr`,
    `sk-SK`
  ];

  // Then load a different font for headings here
  const editedTheme = altFontLocales.some((entry) => entry === locale)
    ? {
        ...theme,
        fonts: {
          ...theme.fonts,
          heading: `Inter Tight, sans-serif`
        }
      }
    : theme;

  return (
    <ChakraProvider theme={editedTheme}>
      <Fonts />
      <LocaleProvider locale={locale} locales={locales}>
        {element}
      </LocaleProvider>
    </ChakraProvider>
  );
};
