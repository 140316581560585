import { extendTheme } from '@chakra-ui/react';

import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { components } from './components';
import { fonts } from './fonts';
import { fontSizes } from './fontSizes';
import { lineHeights } from './lineHeights';
import { radii } from './radii';
import { shadows } from './shadows';
import { styles } from './styles';

export const theme = extendTheme({
  breakpoints,
  colors,
  components,
  fonts,
  fontSizes,
  lineHeights,
  radii,
  shadows,
  styles
});
