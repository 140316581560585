import React from 'react';
import { getImage } from 'gatsby-plugin-image';

const heroComponents = [
  `sections.standard-hero`,
  `sections.home-hero`,
  `sections.brand-hero`,
  `sections.article-hero`
];

const PreloadHeroImage = ({ image, firstComponent }) => {
  /**
   * For fixed layouts use the image passed as a prop.
   */
  let imageToPreload = getImage(image?.localFile) || null;

  /**
   * Otherwise use the first components data if its a hero.
   * A switch statement can be used if we ever have the need to
   * target different image field names.
   */
  if (
    imageToPreload === null &&
    firstComponent &&
    heroComponents.indexOf(firstComponent.strapiComponent) !== -1
  ) {
    /*
      switch (firstComponent.strapiComponent) {
        case 'sections.hero':
          imageToPreload = getImage(firstComponent?.otherField?.localFile);
        default:
          imageToPreload = getImage(firstComponent?.image?.localFile);
      }
    */
    imageToPreload = getImage(firstComponent?.image?.localFile);
  }

  // This has been fixed on another brance, merge in first.
  // Also grab latest DB from develop
  return (
    imageToPreload &&
    imageToPreload.images.sources.length && (
      <link
        rel="preload"
        as="image"
        imageSrcSet={imageToPreload.images.sources[0].srcSet}
        imageSizes={imageToPreload.images.sources[0].sizes}
      />
    )
  );
};

export default PreloadHeroImage;
