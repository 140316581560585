import React from 'react';
import { Icon } from '@chakra-ui/react';

const ArrowRightIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M3.81132 11.8868C3.81132 11.4014 4.20481 11.0079 4.69021 11.0079H16.5635L13.3261 7.8636C12.9692 7.517 12.9692 6.94409 13.326 6.59744C13.6685 6.26473 14.2135 6.26475 14.556 6.59749L20 11.8868L14.556 17.1761C14.2135 17.5088 13.6685 17.5089 13.326 17.1761C12.9692 16.8295 12.9692 16.2566 13.3261 15.91L16.5635 12.7657H4.69021C4.20481 12.7657 3.81132 12.3722 3.81132 11.8868Z" />
  </Icon>
);

export default ArrowRightIcon;
