import React from 'react';
import { Icon } from '@chakra-ui/react';

const InstagramIcon = (props) => (
  <Icon viewBox="0 0 32 32" fill="currentColor" {...props}>
    <path d="M10.4013 2.66663H21.6013C25.868 2.66663 29.3346 6.13329 29.3346 10.4V21.6C29.3346 23.651 28.5199 25.618 27.0696 27.0683C25.6193 28.5185 23.6523 29.3333 21.6013 29.3333H10.4013C6.13464 29.3333 2.66797 25.8666 2.66797 21.6V10.4C2.66797 8.34895 3.48273 6.38195 4.93301 4.93167C6.38329 3.48139 8.35029 2.66663 10.4013 2.66663ZM10.1346 5.33329C8.8616 5.33329 7.6407 5.83901 6.74052 6.73918C5.84035 7.63935 5.33464 8.86025 5.33464 10.1333V21.8666C5.33464 24.52 7.4813 26.6666 10.1346 26.6666H21.868C23.141 26.6666 24.3619 26.1609 25.2621 25.2607C26.1623 24.3606 26.668 23.1397 26.668 21.8666V10.1333C26.668 7.47996 24.5213 5.33329 21.868 5.33329H10.1346ZM23.0013 7.33329C23.4433 7.33329 23.8673 7.50889 24.1798 7.82145C24.4924 8.13401 24.668 8.55793 24.668 8.99996C24.668 9.44199 24.4924 9.86591 24.1798 10.1785C23.8673 10.491 23.4433 10.6666 23.0013 10.6666C22.5593 10.6666 22.1353 10.491 21.8228 10.1785C21.5102 9.86591 21.3346 9.44199 21.3346 8.99996C21.3346 8.55793 21.5102 8.13401 21.8228 7.82145C22.1353 7.50889 22.5593 7.33329 23.0013 7.33329ZM16.0013 9.33329C17.7694 9.33329 19.4651 10.0357 20.7153 11.2859C21.9656 12.5362 22.668 14.2318 22.668 16C22.668 17.7681 21.9656 19.4638 20.7153 20.714C19.4651 21.9642 17.7694 22.6666 16.0013 22.6666C14.2332 22.6666 12.5375 21.9642 11.2873 20.714C10.037 19.4638 9.33463 17.7681 9.33463 16C9.33463 14.2318 10.037 12.5362 11.2873 11.2859C12.5375 10.0357 14.2332 9.33329 16.0013 9.33329ZM16.0013 12C14.9404 12 13.923 12.4214 13.1729 13.1715C12.4227 13.9217 12.0013 14.9391 12.0013 16C12.0013 17.0608 12.4227 18.0782 13.1729 18.8284C13.923 19.5785 14.9404 20 16.0013 20C17.0622 20 18.0796 19.5785 18.8297 18.8284C19.5799 18.0782 20.0013 17.0608 20.0013 16C20.0013 14.9391 19.5799 13.9217 18.8297 13.1715C18.0796 12.4214 17.0622 12 16.0013 12Z" />
  </Icon>
);

export default InstagramIcon;
