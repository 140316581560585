import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Box, AspectRatio, Heading } from '@chakra-ui/react';
import Image from '@components/atoms/Image';

const ProductCard = ({ basePath, product, yMargin = true }) => {
  return (
    <Box
      as={GatsbyLink}
      to={`${basePath}/${product.slug}`}
      display="block"
      className="product-hover"
      position="relative">
      <AspectRatio
        ratio={1}
        display="block"
        w="80%"
        mx="10%"
        my="12"
        mt={yMargin ? null : 0}
        transition="transform .5s ease-in-out"
        sx={{
          '.product-hover:hover &': {
            transform: { md: `auto` },
            scale: { md: `1.1` }
          }
        }}>
        <Image image={product.thumbnail} objectFit="contain" />
      </AspectRatio>
      <Heading
        as="h2"
        size="2xl"
        lineHeight="1.2"
        mb={yMargin ? null : `0`}
        w="100%"
        textAlign="center"
        transition="color .5s ease-in-out"
        sx={{
          '.product-hover:hover &': { color: `milka.primary.dark-purple` }
        }}>
        {product.title}
      </Heading>
    </Box>
  );
};

export default ProductCard;
