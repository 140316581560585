import React, { useContext } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { siteUrl } from '@helpers/environment';
import { LocaleContext } from '@helpers/LocaleProvider';

/**
 * A helper function that checks if the link is internal or external,
 * should it use the GatsbyLink component and what html attributes are required
 * eg. rel="nofollow", target="_blank", etc.
 * @param {string} href The links href attribute.
 * @param {string} locale The links locale lang code.
 * @returns {object} The link props.
 */
function getLinkProps(href, locale) {
  /*
   * Check whether the href is an internal or external link.
   * Anything that isn't using the site url or an alternative link
   * like mailto and tel should have additional props passed.
   */
  if (
    typeof href === `string` &&
    href.length > 0 &&
    href.indexOf(siteUrl) !== 0 &&
    href.match(`^(mailto|tel|sms|callto):`) === null
  ) {
    // External links - should use no follow and open in a new tab.
    if (href.match(`^(http[s]?:)?//`) !== null) {
      return { as: `a`, rel: `nofollow`, target: `_blank`, href };
    }
    // Internal links - this is likely a path which should use the GatsbyLink component.
    const trimmedHref = href.startsWith(`/`) ? href.slice(1) : href;
    return {
      as: GatsbyLink,
      to: `/${locale}/${trimmedHref}`,
      activeClassName: `is-active`
    };
  }

  /*
   * Fallback to a regular link for everything else.
   */
  return { as: `a`, href };
}

const withLink = (WrappedComponent) => {
  const Component = ({ href, ...props }) => {
    const localeContext = useContext(LocaleContext);
    const newProps = {
      ...getLinkProps(href, localeContext.locale),
      ...props
    };

    return <WrappedComponent {...newProps} />;
  };

  return Component;
};

export default withLink;
