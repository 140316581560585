import React, { useContext } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Wrap,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  AspectRatio
} from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import LexicalRichText from '@components/atoms/LexicalRichText';
import { LocaleContext } from '@helpers/LocaleProvider';
import Wrapper from '@components/atoms/Wrapper';
import Section from '@components/atoms/Section';
import ImageGridBlock from '@components/organisms/ImageGridBlock';
import Image from '../atoms/Image';
import LazyLoad from '../atoms/LazyLoad';

const ProductDetail = ({
  allergensTitle,
  ingredientsTitle,
  ingredientsText,
  allergens,
  nutritionalTitle,
  nutritionalCaveatText,
  nutritionalTable,
  image,
  imageGrid
}) => {
  // Sets the current locale .
  const { locale } = useContext(LocaleContext);

  const { allSiteSettings } = useStaticQuery(graphql`
    {
      allSiteSettings: allStrapiSiteSetting {
        nodes {
          locale
          nutritionalColumnHeaders {
            gramColumnHeader
            percentColumnHeader
            portionColumnHeader
          }
          nutritionalRowHeaders {
            carbohydrateRowHeader
            energKcalRowHeader
            energyRowHeader
            fatRowHeader
            fibreRowHeader
            proteinRowHeader
            saltRowHeader
            saturatesRowHeader
            sugarRowHeader
          }
        }
      }
    }
  `);
  const siteSettings = allSiteSettings.nodes.find(
    (node) => node.locale === locale
  );
  const thStyles = {
    fontFamily: `body`,
    textTransform: `none`,
    fontSize: `md`,
    px: { base: 2, md: 6 },
    py: 4
  };
  const trStyles = {
    borderBottom: `.0625rem solid`,
    borderColor: `milka.shades.white`
  };
  const tdStyles = {
    padding: { base: `1rem .75rem`, md: `1rem 1.5rem` }
  };
  return (
    <Section mb="16">
      <Wrapper>
        <Grid templateColumns={{ md: `repeat(12, 1fr)` }} columnGap={5}>
          <GridItem colSpan={{ md: 6 }} mb={{ base: 12, md: 0 }}>
            {image && (
              <LazyLoad>
                <AspectRatio ratio={1} overflow="hidden" display="block">
                  <Image image={image} />
                </AspectRatio>
              </LazyLoad>
            )}
          </GridItem>
          <GridItem colStart={{ xl: 8 }} colSpan={{ md: 6, xl: 4 }}>
            <LazyLoad>
              {allergensTitle && (
                <Heading as="h2" fontSize="3xl" textTransform="uppercase">
                  {allergensTitle}
                </Heading>
              )}
              <Wrap
                spacingX={{ base: 6, md: 12 }}
                spacingY={{ base: 3, md: 6 }}
                flexWrap="wrap"
                mb="12">
                {typeof allergens !== `undefined` &&
                  allergens.map((allergen) => (
                    <Box key={allergen.title}>
                      <Box w="14" mb="3">
                        <Image image={allergen.image} mx="auto" />
                      </Box>
                      <Text
                        fontFamily="heading"
                        fontSize="md"
                        lineHeight="1.2"
                        textAlign="center"
                        textTransform="uppercase">
                        {allergen.title}
                      </Text>
                    </Box>
                  ))}
              </Wrap>
            </LazyLoad>
            <LazyLoad>
              {ingredientsTitle && (
                <Heading as="h2" textTransform="uppercase" fontSize="3xl">
                  {ingredientsTitle}
                </Heading>
              )}
              {ingredientsText && (
                <Box>
                  <LexicalRichText
                    data={{ richText: { ...ingredientsText.richText } }}
                    paddingSides="0"
                    maxWidth="none"
                  />
                </Box>
              )}
            </LazyLoad>
          </GridItem>
        </Grid>
      </Wrapper>
      <Wrapper px={{ base: 6, md: 14 }}>
        <Grid
          templateColumns={{ md: `repeat(12, 1fr)` }}
          columnGap={5}
          mt={{ base: 12, md: 28 }}>
          <GridItem colSpan={{ md: 12, xl: 10 }} colStart={{ xl: 2 }}>
            <LazyLoad>
              {nutritionalTitle && (
                <Heading
                  as="h2"
                  textTransform="uppercase"
                  fontSize="3xl"
                  px={{ base: 6, md: 0 }}>
                  {nutritionalTitle}
                </Heading>
              )}
              <TableContainer>
                <Table
                  variant="unstyled"
                  sx={{
                    tableLayout: { md: `fixed` }
                  }}
                  whiteSpace="normal">
                  <Thead bgColor="milka.primary.dark-purple">
                    <Tr sx={trStyles}>
                      <Th />
                      <Th sx={thStyles}>
                        {
                          siteSettings.nutritionalColumnHeaders
                            ?.gramColumnHeader
                        }
                      </Th>
                      <Th sx={thStyles}>
                        {
                          siteSettings.nutritionalColumnHeaders
                            ?.portionColumnHeader
                        }
                      </Th>
                      <Th sx={thStyles}>
                        {
                          siteSettings.nutritionalColumnHeaders
                            ?.percentColumnHeader
                        }
                      </Th>
                    </Tr>
                  </Thead>
                  {typeof nutritionalTable !== `undefined` && (
                    <Tbody>
                      <Tr sx={trStyles}>
                        <Td sx={tdStyles}>
                          {siteSettings.nutritionalRowHeaders?.energyRowHeader}
                        </Td>
                        <Td sx={tdStyles}>{nutritionalTable?.energyGram}</Td>
                        <Td sx={tdStyles}>{nutritionalTable?.energyPortion}</Td>
                        <Td sx={tdStyles}>{nutritionalTable?.energyPercent}</Td>
                      </Tr>
                      <Tr sx={trStyles}>
                        <Td sx={tdStyles}>
                          {
                            siteSettings.nutritionalRowHeaders
                              ?.energKcalRowHeader
                          }
                        </Td>
                        <Td sx={tdStyles}>
                          {nutritionalTable?.energyKcalGram}
                        </Td>
                        <Td sx={tdStyles}>
                          {nutritionalTable?.energyKcalPortion}
                        </Td>
                        <Td sx={tdStyles}>
                          {nutritionalTable?.energyKcalPercent}
                        </Td>
                      </Tr>
                      <Tr sx={trStyles}>
                        <Td sx={tdStyles}>
                          {siteSettings.nutritionalRowHeaders?.fatRowHeader}
                        </Td>
                        <Td sx={tdStyles}>{nutritionalTable?.fatGram}</Td>
                        <Td sx={tdStyles}>{nutritionalTable?.fatPortion}</Td>
                        <Td sx={tdStyles}>{nutritionalTable?.fatPercent}</Td>
                      </Tr>
                      <Tr sx={trStyles}>
                        <Td sx={tdStyles}>
                          {
                            siteSettings.nutritionalRowHeaders
                              ?.saturatesRowHeader
                          }
                        </Td>
                        <Td sx={tdStyles}>{nutritionalTable?.saturatesGram}</Td>
                        <Td sx={tdStyles}>
                          {nutritionalTable?.saturatesPortion}
                        </Td>
                        <Td sx={tdStyles}>
                          {nutritionalTable?.saturatesPercent}
                        </Td>
                      </Tr>
                      <Tr sx={trStyles}>
                        <Td sx={tdStyles}>
                          {
                            siteSettings.nutritionalRowHeaders
                              ?.carbohydrateRowHeader
                          }
                        </Td>
                        <Td sx={tdStyles}>
                          {nutritionalTable?.carbohydrateGram}
                        </Td>
                        <Td sx={tdStyles}>
                          {nutritionalTable?.carbohydratePortion}
                        </Td>
                        <Td sx={tdStyles}>
                          {nutritionalTable?.carbohydratePercent}
                        </Td>
                      </Tr>
                      <Tr sx={trStyles}>
                        <Td sx={tdStyles}>
                          {siteSettings.nutritionalRowHeaders?.sugarRowHeader}
                        </Td>
                        <Td sx={tdStyles}>{nutritionalTable?.sugarsGram}</Td>
                        <Td sx={tdStyles}>{nutritionalTable?.sugarsPortion}</Td>
                        <Td sx={tdStyles}>{nutritionalTable?.sugarsPercent}</Td>
                      </Tr>
                      <Tr sx={trStyles}>
                        <Td sx={tdStyles}>
                          {siteSettings.nutritionalRowHeaders?.fibreRowHeader}
                        </Td>
                        <Td sx={tdStyles}>{nutritionalTable?.fibreGram}</Td>
                        <Td sx={tdStyles}>{nutritionalTable?.fibrePortion}</Td>
                        <Td sx={tdStyles}>{nutritionalTable?.fibrePercent}</Td>
                      </Tr>
                      <Tr sx={trStyles}>
                        <Td sx={tdStyles}>
                          {siteSettings.nutritionalRowHeaders?.proteinRowHeader}
                        </Td>
                        <Td sx={tdStyles}>{nutritionalTable?.proteinGram}</Td>
                        <Td sx={tdStyles}>
                          {nutritionalTable?.proteinPortion}
                        </Td>
                        <Td sx={tdStyles}>
                          {nutritionalTable?.proteinPercent}
                        </Td>
                      </Tr>
                      <Tr sx={trStyles}>
                        <Td sx={tdStyles}>
                          {siteSettings.nutritionalRowHeaders?.saltRowHeader}
                        </Td>
                        <Td sx={tdStyles}>{nutritionalTable?.saltGram}</Td>
                        <Td sx={tdStyles}>{nutritionalTable?.saltPortion}</Td>
                        <Td sx={tdStyles}>{nutritionalTable?.saltPercent}</Td>
                      </Tr>
                    </Tbody>
                  )}
                </Table>
              </TableContainer>
              <Text fontSize="sm" mt="6" mb="0" px={{ base: 6, md: 0 }}>
                {nutritionalCaveatText}
              </Text>
            </LazyLoad>
          </GridItem>
        </Grid>
      </Wrapper>
      {imageGrid && (
        <Wrapper mt={{ base: 12, md: 28 }}>
          <LazyLoad>
            <ImageGridBlock data={imageGrid} />
          </LazyLoad>
        </Wrapper>
      )}
    </Section>
  );
};

export default ProductDetail;
