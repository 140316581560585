/*
 * A helper function to scroll the user to a target.
 * Returns a promise to wait until the scrolling is roughly finished.
 */
const scrollToPromise = (target, promiseTimeout = 500) => {
  return new Promise((resolve) => {
    window.scrollTo({ top: target.offsetTop, behavior: `smooth` });
    setTimeout(resolve, promiseTimeout);
  });
};

export default scrollToPromise;
