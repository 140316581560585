import React from 'react';
import { Icon } from '@chakra-ui/react';

const YoutubeIcon = (props) => (
  <Icon viewBox="0 0 32 32" fill="currentColor" {...props}>
    <g clipPath="url(#clip0_4928_6242)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0013 5.33331C17.1413 5.33331 18.3106 5.36265 19.444 5.41065L20.7826 5.47465L22.064 5.55065L23.264 5.63198L24.36 5.71731C25.5494 5.80835 26.6686 6.31591 27.5207 7.1507C28.3728 7.98549 28.9032 9.09403 29.0186 10.2813L29.072 10.848L29.172 12.0613C29.2653 13.3186 29.3346 14.6893 29.3346 16C29.3346 17.3106 29.2653 18.6813 29.172 19.9386L29.072 21.152C29.0546 21.3466 29.0373 21.5346 29.0186 21.7186C28.9032 22.9061 28.3725 24.0148 27.5202 24.8497C26.6678 25.6845 25.5483 26.1919 24.3586 26.2826L23.2653 26.3666L22.0653 26.4493L20.7826 26.5253L19.444 26.5893C18.2971 26.6392 17.1493 26.6649 16.0013 26.6666C14.8533 26.6649 13.7055 26.6392 12.5586 26.5893L11.22 26.5253L9.93863 26.4493L8.73864 26.3666L7.64263 26.2826C6.45324 26.1916 5.33404 25.6841 4.48193 24.8493C3.62983 24.0145 3.0994 22.9059 2.98397 21.7186L2.93064 21.152L2.83064 19.9386C2.72869 18.6282 2.67443 17.3144 2.66797 16C2.66797 14.6893 2.7373 13.3186 2.83064 12.0613L2.93064 10.848C2.94797 10.6533 2.9653 10.4653 2.98397 10.2813C3.09936 9.09424 3.62959 7.98587 4.48142 7.1511C5.33326 6.31634 6.45214 5.80865 7.6413 5.71731L8.73597 5.63198L9.93597 5.55065L11.2186 5.47465L12.5573 5.41065C13.7046 5.36082 14.8529 5.33504 16.0013 5.33331ZM13.3346 12.7666V19.2333C13.3346 19.8493 14.0013 20.2333 14.5346 19.9266L20.1346 16.6933C20.2565 16.6232 20.3577 16.5221 20.4281 16.4004C20.4985 16.2787 20.5355 16.1406 20.5355 16C20.5355 15.8594 20.4985 15.7213 20.4281 15.5995C20.3577 15.4778 20.2565 15.3768 20.1346 15.3066L14.5346 12.0746C14.413 12.0044 14.275 11.9674 14.1345 11.9675C13.994 11.9675 13.856 12.0045 13.7344 12.0748C13.6128 12.1451 13.5118 12.2461 13.4416 12.3678C13.3714 12.4895 13.3345 12.6275 13.3346 12.768V12.7666Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_4928_6242">
        <rect width="32" height="32" />
      </clipPath>
    </defs>
  </Icon>
);

export default YoutubeIcon;
