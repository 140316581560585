import React, { useContext } from 'react';
import { Link as GatsbyLink, graphql, useStaticQuery } from 'gatsby';
import { Box, AspectRatio, Heading, HStack } from '@chakra-ui/react';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import { ButtonLink } from '@components/atoms/Button';
import { LocaleContext } from '@helpers/LocaleProvider';
import dataLayerPush from '@helpers/dataLayerPush';
import LazyLoad from '../atoms/LazyLoad';

const OtherNewsRow = ({ data: { title, articles, ctaText } }) => {
  // Sets the current locale .
  const { locale } = useContext(LocaleContext);
  // Query allStrapiArticleListing to get slug of product listing page.
  const { allStrapiArticleListing } = useStaticQuery(graphql`
    {
      allStrapiArticleListing {
        nodes {
          locale
          slug
        }
      }
    }
  `);

  // Find matching locale.
  const strapiArticleListing = allStrapiArticleListing.nodes.find(
    (node) => node.locale === locale
  );
  const basePath = `${locale}/${strapiArticleListing.slug}`;

  return (
    <Section mb={0}>
      <LazyLoad>
        <Wrapper>
          <Heading
            fontSize="4xl"
            textAlign="center"
            textTransform="uppercase"
            mb={{ base: 8, md: 16 }}>
            {title}
          </Heading>
          <HStack flexWrap="wrap" justifyContent={{ md: `space-between` }}>
            {articles?.map((article) => (
              <Box
                key={article.id}
                width={{ base: `100%`, md: `calc(50% - 2rem)` }}
                mb={{ base: 6, md: 28 }}>
                <LazyLoad>
                  <Box className="button-hover">
                    <Box as={GatsbyLink} to={`/${basePath}/${article.slug}`}>
                      <AspectRatio
                        ratio={4 / 3}
                        overflow="hidden"
                        display="block">
                        <Image image={article.thumbnail} />
                      </AspectRatio>
                      <Heading
                        as="h2"
                        fontSize={{ base: `lg`, md: `3xl` }}
                        color="white"
                        pt={{ base: 8, md: 10 }}
                        mb={{ base: 6, md: 8 }}
                        textTransform="uppercase">
                        {article.title}
                      </Heading>
                    </Box>
                    <ButtonLink
                      href={`/${basePath}/${article.slug}`}
                      variant="tertiary"
                      size="ellipsed"
                      icon
                      onClick={() => {
                        dataLayerPush({
                          event: `cta_click`,
                          interaction: {
                            click_text: article.title,
                            link_url: `/${basePath}/${article.slug}`,
                            cta_type: `news_card`
                          }
                        });
                      }}>
                      {ctaText}
                    </ButtonLink>
                  </Box>
                </LazyLoad>
              </Box>
            ))}
          </HStack>
        </Wrapper>
      </LazyLoad>
    </Section>
  );
};

export default OtherNewsRow;
