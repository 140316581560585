import React from 'react';
import { Icon } from '@chakra-ui/react';

const ChevronLeftIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4741 11.9904L17.0852 17.4455C17.4176 17.7687 17.4066 18.3059 17.0612 18.6151L16.0713 19.5014C15.7578 19.7821 15.2804 19.7719 14.9791 19.4781L7.30001 11.9904L14.9792 4.52059C15.2805 4.22744 15.7574 4.21758 16.0706 4.498L17.0602 5.38395C17.406 5.69354 17.4166 6.23147 17.0833 6.55447L11.4741 11.9904Z"
    />
  </Icon>
);

export default ChevronLeftIcon;
