import buttonMask from '@assets/images/button-mask.png';

export const components = {
  Heading: {
    baseStyle: {
      color: `white`,
      mb: { base: 4, md: 6 }
    },
    sizes: {
      xs: {
        fontSize: [`xs`, `xs`, `sm`]
      },
      sm: {
        fontSize: [`sm`, `sm`, `md`]
      },
      md: {
        fontSize: [`lg`, `lg`, `xl`]
      },
      lg: {
        fontSize: [`xl`, `xl`, `2xl`]
      },
      xl: {
        fontSize: `3xl`
      },
      '2xl': {
        fontSize: [`3xl`, `3xl`, `4xl`]
      },
      '3xl': {
        fontSize: [`4xl`, `4xl`, `5xl`]
      },
      '4xl': {
        fontSize: [`4xl`, `4xl`, `5xl`, `6xl`]
      },
      '5xl': {
        fontSize: [`4xl`, `4xl`, `6xl`, `8xl`]
      }
    },
    variants: {
      detail: {
        fontFamily: `body`,
        fontWeight: `600`
      },
      script: {
        fontFamily: `headingScript`
      }
    }
  },
  Text: {
    baseStyle: {
      mb: { base: 4, md: 6 }
    }
  },
  List: {
    baseStyle: {
      container: {
        mb: { base: 4, md: 6 }
      }
    }
  },
  Button: {
    defaultProps: {
      colorScheme: null
    },
    baseStyle: {
      position: `relative`,
      fontFamily: `headingSub`,
      letterSpacing: `-0.0156rem`,
      textTransform: `uppercase`,
      cursor: `pointer`
    },
    sizes: {
      ellipsedSmall: {
        fontSize: `md`
      },
      ellipsedMedium: {
        fontSize: `2xl`
      },
      sm: ({ variant }) => ({
        h: variant === `primary` || `secondary` ? `3.438rem` : null,
        px: variant === `primary` || `secondary` ? `2.375rem` : null,
        minW: null,
        fontSize: { base: `md`, lg: `lg`, xl: `xl` }
      }),
      md: ({ variant }) => ({
        h: variant === `primary` || `secondary` ? `4.5rem` : null,
        px: variant === `primary` || `secondary` ? `4rem` : null,
        minW: null,
        fontSize: `xl`
      })
    },
    variants: {
      primary: {
        backgroundColor: `milka.primary.dark-purple`,
        color: `white`,
        borderRadius: `5.5rem`,
        overflow: `hidden`,
        transition: `background-color 0.8s, color 0.8s, transform 0.8s`,
        '&::before': {
          content: `""`,
          position: `absolute`,
          top: `0`,
          bottom: `0`,
          left: `0`,
          right: `0`,
          width: `100%`,
          zIndex: `1`,
          opacity: 1,
          transition: `transform 0.8s, opacity 0.8s, box-shadow 0.8s`,
          transform: `translateX(-100%)`,
          backgroundImage: { md: `/**/url("${buttonMask}")` },
          backgroundPositionX: `0%`,
          backgroundSize: `100% 100%`,
          backgroundRepeat: `no-repeat`
        },
        '&::after': {
          content: `attr(data-text)`,
          position: `absolute`,
          top: `0`,
          bottom: `0`,
          left: `0`,
          right: `0`,
          display: `flex`,
          alignItems: `center`,
          justifyContent: `center`,
          transform: `translateX(-100%)`,
          color: `milka.primary.dark-purple`
        },
        _hover: {
          backgroundColor: { md: `milka.shades.white` },
          color: `transparent`,
          transition: `background-color 0.8s, color 0.8s, transform 0.8s, box-shadow 0.8s`,
          transform: { md: `scale(1.1)` },
          boxShadow: { md: `0.75rem 0.75rem 0.625rem 0 #2c27638a` },
          '&::before': {
            transform: `translateX(10%)`,
            transition: `transform 0.8s, opacity 0.8s 0.4s`,
            opacity: 0
          },
          '&::after': {
            transform: `translateX(0)`,
            transition: `transform 0.8s`
          }
        }
      },
      secondary: {
        border: `0.125rem solid #3B2774`,
        backgroundColor: `transparent`,
        color: `white`,
        borderRadius: `5.5rem`,
        overflow: `hidden`,
        transition: `background-color 0.8s, color 0.8s, transform 0.8s`,
        '&::before': {
          content: `""`,
          position: `absolute`,
          top: `0`,
          bottom: `0`,
          left: `0`,
          right: `0`,
          width: `100%`,
          zIndex: `1`,
          opacity: 1,
          transition: `transform 0.8s, opacity 0.8s, box-shadow 0.8s`,
          transform: `translateX(-100%)`,
          backgroundImage: { md: `/**/url("${buttonMask}")` },
          backgroundPositionX: `0%`,
          backgroundSize: `100% 100%`,
          backgroundRepeat: `no-repeat`
        },
        '&::after': {
          content: `attr(data-text)`,
          position: `absolute`,
          top: `0`,
          bottom: `0`,
          left: `0`,
          right: `0`,
          display: `flex`,
          alignItems: `center`,
          justifyContent: `center`,
          transform: `translateX(-100%)`,
          color: `milka.shades.white`
        },
        _hover: {
          backgroundColor: { md: `milka.primary.dark-purple` },
          color: `transparent`,
          transition: `background-color 0.8s, color 0.8s, transform 0.8s, box-shadow 0.8s`,
          transform: { md: `scale(1.1)` },
          boxShadow: { md: `0.75rem 0.75rem 0.625rem 0 #2c27638a` },
          '&::before': {
            transform: `translateX(10%)`,
            transition: `transform 1.2s, opacity 0.8s 0.2s`,
            opacity: 0
          },
          '&::after': {
            transform: `translateX(0)`,
            transition: `transform 0.8s`
          }
        }
      },
      tertiary: {
        color: `white`,
        fontFamily: `Myriad Pro`,
        textTransform: `capitalize`
      },
      quaternary: {
        color: `white`,
        border: `0.125rem solid`,
        borderColor: `milka.primary.dark-purple`
      }
    }
  },
  Link: {
    baseStyle: {
      _hover: {
        textDecoration: `none`
      }
    },
    variants: {
      header: {
        color: `secondary.pink`,
        fontWeight: `bold`,
        textDecoration: `underline`,
        _hover: {
          textDecoration: `underline`
        }
      }
    }
  },
  Radio: {
    baseStyle: {
      control: {
        borderWidth: `1px`,
        borderColor: `primary.dark-blue`,
        _checked: {
          background: `primary.dark-blue`,
          borderColor: `primary.dark-blue`,
          _hover: {
            background: `primary.dark-blue`,
            borderColor: `primary.dark-blue`
          }
        }
      },
      label: {
        fontFamily: `heading`,
        fontWeight: `bold`,
        color: `secondary.pink`,
        textDecoration: `underline`
      }
    },
    sizes: {
      md: {
        control: {
          w: 6,
          h: 6
        }
      }
    }
  },
  Checkbox: {
    baseStyle: {
      control: {
        borderWidth: `1px`,
        borderColor: `primary.dark-blue`,
        borderRadius: 4,
        _checked: {
          background: `primary.dark-blue`,
          borderColor: `primary.dark-blue`,
          _hover: {
            background: `primary.dark-blue`,
            borderColor: `primary.dark-blue`
          }
        }
      },
      label: {
        fontFamily: `heading`,
        fontWeight: `bold`,
        color: `secondary.pink`,
        textDecoration: `underline`
      }
    },
    sizes: {
      md: {
        control: {
          w: 6,
          h: 6
        }
      }
    }
  }
};
