import React, { useRef, useEffect } from 'react';
import { Box, Flex, VStack, Text } from '@chakra-ui/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import ButtonGroup from '@components/molecules/ButtonGroup';
import TwoLineHeader from '@components/molecules/TwoLineHeader';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
}

const CenteredTextBlock = ({
  data: {
    titleLineOne,
    titleLineTwo,
    text,
    ctas,
    backgroundImage,
    removeBottomSpacing
  },
  headingType,
  isHero
}) => {
  const containerRef = useRef(null);
  const titleRef = useRef(null);
  const textRef = useRef(null);
  const buttonRef = useRef(null);
  const imageRef = useRef(null);
  useEffect(() => {
    const elements = [
      titleRef.current,
      textRef.current,
      buttonRef.current
    ].filter((ref) => ref !== null);

    gsap
      .timeline({
        scrollTrigger: {
          trigger: containerRef.current
        }
      })
      .to(elements, { opacity: 1, stagger: 0.5 }, 0.5);
    if (imageRef.current !== null) {
      gsap.to(imageRef.current, { opacity: 1 }, 0.5);
    }
  }, []);

  return (
    <Flex
      ref={containerRef}
      as="section"
      position="relative"
      mt={isHero ? null : { base: 12, md: 16 }}
      pt={{ base: `4.75rem`, lg: `12.5rem` }}
      pb={removeBottomSpacing ? 0 : { base: 12, md: 24 }}>
      {backgroundImage && (
        <Box
          ref={imageRef}
          w="100%"
          position="absolute"
          left="0"
          top="0"
          overflow="hidden"
          display="flex"
          alignItems="center"
          justifyContent="center"
          opacity={0}
          willChange="opacity">
          <Box
            minW="34.6975rem"
            maxW="90rem"
            mx="auto"
            pointerEvents="none"
            willChange="transform">
            <Image image={backgroundImage} />
          </Box>
        </Box>
      )}
      <Wrapper zIndex="1">
        <VStack alignItems="normal" spacing={{ base: 8, md: 10 }}>
          <Box ref={titleRef} opacity={0} willChange="opacity">
            <TwoLineHeader
              lineOne={titleLineOne}
              lineTwo={titleLineTwo}
              headingType={headingType}
            />
          </Box>
          {text && (
            <Text
              ref={textRef}
              textAlign="center"
              fontSize={{ base: `xl`, lg: `2xl` }}
              fontWeight="600"
              w={{ lg: `45%` }}
              maxW={{ base: `20.4375rem`, lg: `45%` }}
              mx="auto"
              opacity={0}
              willChange="opacity">
              {text}
            </Text>
          )}
          {ctas && ctas.length > 0 && (
            <Box ref={buttonRef} opacity={0} willChange="opacity">
              <ButtonGroup
                ctas={ctas}
                justify="center"
                dataLayerType="home_hero"
                size="md"
                maxW="max-content"
                mx="auto"
              />
            </Box>
          )}
        </VStack>
      </Wrapper>
    </Flex>
  );
};

export default CenteredTextBlock;
