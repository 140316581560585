import React, { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';

const CookieBotDeclaration = ({ id }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    if (!document || !id || !elementRef.current) {
      return () => {};
    }

    const ref = elementRef.current;
    const script = document.createElement(`script`);
    script.setAttribute(`id`, `CookieDeclaration`);
    script.setAttribute(`src`, `https://consent.cookiebot.com/${id}/cd.js`);
    script.setAttribute(`type`, `text/javascript`);
    script.setAttribute(`async`, true);

    ref.appendChild(script);

    return () => {
      ref.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box ref={elementRef} />;
};

export default CookieBotDeclaration;
