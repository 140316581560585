import React from 'react';
import { Icon } from '@chakra-ui/react';

const ChevronRightIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5259 11.9904L7.91478 17.4455C7.58236 17.7687 7.59339 18.3059 7.93882 18.6151L8.92875 19.5014C9.24225 19.7821 9.71959 19.7719 10.0209 19.4781L17.7 11.9904L10.0208 4.52059C9.71949 4.22744 9.24264 4.21758 8.92941 4.498L7.93984 5.38395C7.59404 5.69354 7.58341 6.23147 7.91671 6.55447L13.5259 11.9904Z"
    />
  </Icon>
);

export default ChevronRightIcon;
