import React, { useState, useEffect } from 'react';
import { ButtonGroup, HStack, Box, IconButton } from '@chakra-ui/react';
import PaginationButton from '@components/atoms/PaginationButton';
import PaginationDisc from '@components/atoms/PaginationDisc';
import { ChevronLeftIcon, ChevronRightIcon } from '@components/atoms/icons';

const ButtonsVariant = ({
  itemPadding,
  paginationItems,
  numPages,
  activePage,
  onItemClicked = (index) => {},
  onPreviousSet = () => {},
  onNextSet = () => {}
}) => {
  const [group, setGroup] = useState([]);

  useEffect(() => {
    const previousPage = activePage - 1;
    const nextPage = activePage + 1;

    const offsetLeft = previousPage - itemPadding;
    const offsetRight = nextPage + itemPadding;

    const overflowLeft = offsetLeft < 0 ? Math.abs(offsetLeft) : 0;
    const overflowRight =
      offsetRight > paginationItems.length
        ? offsetRight - paginationItems.length - 1
        : 0;

    const paddedItems = paginationItems.filter((item, index) => {
      const pageFromIndex = index + 1;
      const isCurrent = pageFromIndex === activePage;

      const allowLeft =
        pageFromIndex < activePage &&
        pageFromIndex > previousPage - (itemPadding + overflowRight);

      const allowRight =
        pageFromIndex > activePage &&
        pageFromIndex < nextPage + itemPadding + overflowLeft;

      return isCurrent || allowLeft || allowRight;
    });

    setGroup(paddedItems);
  }, [paginationItems, itemPadding, activePage, numPages]);

  return (
    <ButtonGroup>
      {activePage > 1 && (
        <Box pr={{ md: 10 }}>
          <IconButton
            isRound
            aria-label="Previous articles"
            width={{ base: 12, md: 14 }}
            height={{ base: 12, md: 14 }}
            backgroundColor="black"
            icon={<ChevronLeftIcon />}
            onClick={onPreviousSet}
            _hover={{
              textColor: `milka.shades.white`,
              backgroundColor: `white`
            }}
          />
        </Box>
      )}
      {group.map((itemPage) => (
        <PaginationButton
          key={itemPage}
          value={itemPage}
          isActive={itemPage === activePage}
          onClick={() => onItemClicked(itemPage)}
        />
      ))}
      {activePage < numPages && (
        <Box pl={{ md: 6 }} marginInlineStart={0}>
          <IconButton
            isRound
            aria-label="More articles"
            width={{ base: 12, md: 14 }}
            height={{ base: 12, md: 14 }}
            backgroundColor="milka.primary.dark-purple"
            icon={<ChevronRightIcon />}
            onClick={onNextSet}
            _hover={{
              textColor: `milka.primary.dark-purple`,
              backgroundColor: `white`
            }}
          />
        </Box>
      )}
    </ButtonGroup>
  );
};

const DiscsVariant = ({
  paginationItems,
  activePage,
  onItemClicked = (index) => {}
}) => (
  <HStack>
    {paginationItems.map((item, index) => (
      <PaginationDisc
        key={item}
        isActive={item === activePage}
        onClick={() => onItemClicked(index)}
      />
    ))}
  </HStack>
);

const Variants = {
  buttons: ButtonsVariant,
  discs: DiscsVariant
};

const Pagination = ({
  displayAs = null,
  activePage,
  numPages,
  divideAt = 5,
  itemPadding = 2,
  paginationItems = [],
  onItemClicked = (index) => {},
  onPreviousSet = () => {},
  onNextSet = () => {}
}) => {
  const [currentSetIndex, setCurrentSetIndex] = useState(0);
  const [maxSetIndex, setMaxSetIndex] = useState(0);

  const handleOnPrevSet = () => {
    const nextValue = currentSetIndex <= 0 ? 0 : currentSetIndex - 1;
    setCurrentSetIndex(nextValue);
    onPreviousSet();
  };

  const handleOnNextSet = () => {
    const nextValue =
      currentSetIndex >= maxSetIndex ? maxSetIndex : currentSetIndex + 1;
    setCurrentSetIndex(nextValue);
    onNextSet();
  };

  useEffect(() => {
    const parsedDivideAt = parseInt(divideAt, 10);
    const calcedMax = Math.floor(paginationItems.length / parsedDivideAt);
    const remainder = paginationItems.length % calcedMax;
    const maxIndex = calcedMax + remainder;

    setMaxSetIndex(maxIndex);
  }, [paginationItems, divideAt, currentSetIndex]);

  const Variant = Variants[String(displayAs)];

  if (typeof Variant === `undefined`) {
    return (
      <Box>
        Variant <b>{displayAs}</b> not found. Try using:{` `}
        {Object.keys(Variants).map((s, i, arr) => {
          return i >= arr.length - 1 ? `or ${s}.` : `${s}, `;
        })}
      </Box>
    );
  }

  return (
    <Variant
      itemPadding={parseInt(itemPadding, 10)}
      paginationItems={paginationItems}
      activePage={activePage}
      numPages={numPages}
      maxSetIndex={maxSetIndex}
      onItemClicked={onItemClicked}
      onPreviousSet={handleOnPrevSet}
      onNextSet={handleOnNextSet}
    />
  );
};

export default Pagination;
