import React from 'react';
import { Global } from '@emotion/react';
import MyriadPro from '@assets/fonts/MyriadPro-Regular.woff2';
import MyriadProSemibold from '@assets/fonts/MyriadPro-Semibold.woff2';
import GoboldBold from '@assets/fonts/Gobold-Bold.woff2';
import GoodHeadlineProBold from '@assets/fonts/GoodHeadlinePro-Bold.woff2';
import Stash from '@assets/fonts/Stash.woff2';
import InterTightLatin from '@assets/fonts/inter-tight/NGSwv5HMAFg6IuGlBNMjxLsH8ahuQ2e8.woff2';
import InterTightLatinExt from '@assets/fonts/inter-tight/NGSwv5HMAFg6IuGlBNMjxLsJ8ahuQ2e8Smg.woff2';
import InterTightCyrillic from '@assets/fonts/inter-tight/NGSwv5HMAFg6IuGlBNMjxLsD8ahuQ2e8Smg.woff2';
import InterTightCyrillicExt from '@assets/fonts/inter-tight/NGSwv5HMAFg6IuGlBNMjxLsK8ahuQ2e8Smg.woff2';
import InterTightGreek from '@assets/fonts/inter-tight/NGSwv5HMAFg6IuGlBNMjxLsE8ahuQ2e8Smg.woff2';
import InterTightGreekExt from '@assets/fonts/inter-tight/NGSwv5HMAFg6IuGlBNMjxLsL8ahuQ2e8Smg.woff2';
import InterTightVietnamese from '@assets/fonts/inter-tight/NGSwv5HMAFg6IuGlBNMjxLsI8ahuQ2e8Smg.woff2';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Inter Tight';
        font-style: normal;
        font-weight: 100 900;
        font-display: swap;
        src: url(${InterTightLatin}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      @font-face {
        font-family: 'Inter Tight';
        font-style: normal;
        font-weight: 100 900;
        font-display: swap;
        src: url(${InterTightLatinExt}) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      @font-face {
        font-family: 'Inter Tight';
        font-style: normal;
        font-weight: 100 900;
        font-display: swap;
        src: url(${InterTightCyrillic}) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      @font-face {
        font-family: 'Inter Tight';
        font-style: normal;
        font-weight: 100 900;
        font-display: swap;
        src: url(${InterTightCyrillicExt}) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
      }
      @font-face {
        font-family: 'Inter Tight';
        font-style: normal;
        font-weight: 100 900;
        font-display: swap;
        src: url(${InterTightGreek}) format('woff2');
        unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
      }
      @font-face {
        font-family: 'Inter Tight';
        font-style: normal;
        font-weight: 100 900;
        font-display: swap;
        src: url(${InterTightGreekExt}) format('woff2');
        unicode-range: U+1F00-1FFF;
      }
      @font-face {
        font-family: 'Inter Tight';
        font-style: normal;
        font-weight: 100 900;
        font-display: swap;
        src: url(${InterTightVietnamese}) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
      }
      @font-face {
        font-family: 'GoBold';
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        src: url(${GoboldBold}) format('woff2');
      }
      @font-face {
        font-family: 'GoodHeadline';
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        src: url(${GoodHeadlineProBold}) format('woff2');
      }
      @font-face {
        font-family: 'Stash';
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        src: url(${Stash}) format('woff2');
      }
      @font-face {
        font-family: 'Myriad Pro';
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        src: url(${MyriadPro}) format('woff2');
      }
      @font-face {
        font-family: 'Myriad Pro';
        font-weight: 900;
        font-style: normal;
        font-display: swap;
        src: url(${MyriadProSemibold}) format('woff2');
      }
    `}
  />
);

export default Fonts;
