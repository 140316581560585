import React, { useCallback } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import Link from '@components/atoms/Link';
import dataLayerPush from '@helpers/dataLayerPush';

const FooterNav = ({ footerMenu, locale, ...props }) => {
  const handleNavigate = useCallback((url, title) => {
    dataLayerPush({
      event: `footer_link_click`,
      interaction: {
        click_text: title,
        link_url: url
      }
    });
  }, []);
  return (
    <Box {...props}>
      {footerMenu?.nodes[0].attributes?.items?.data?.length > 0 && (
        <HStack
          as="ul"
          flexDirection={{ base: `column`, lg: `row` }}
          listStyleType="none"
          spacing={{ base: 4, lg: `6.6875rem` }}>
          {footerMenu.nodes[0].attributes.items.data.map(
            ({ attributes: primaryItem }) => (
              <Box key={primaryItem.title} as="li">
                <Link
                  href={primaryItem.url}
                  onClick={
                    () => handleNavigate(primaryItem.url, primaryItem.title)
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  display="block"
                  fontFamily="headingSub"
                  fontWeight="bold"
                  fontSize="xl"
                  lineHeight="1.2"
                  textTransform="uppercase"
                  textDecoration="none"
                  transition=".3s ease-in-out"
                  _hover={{
                    transform: `auto`,
                    scale: `1.1`
                  }}>
                  {primaryItem.title}
                </Link>
              </Box>
            )
          )}
        </HStack>
      )}
    </Box>
  );
};

export default FooterNav;
