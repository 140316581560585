import React, { lazy, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SuspenseLoader from '@components/atoms/SuspenseLoader';
import SuspenseHelper from '@components/atoms/SuspenseHelper';
import { LocaleContext } from '@helpers/LocaleProvider';

/* Lazy-load share page block to avoid SSR issues */
const SharePageBlock = lazy(
  () => import(`@components/atoms/SharePageBlock`)
  // eslint-disable-next-line function-paren-newline
);

const Share = ({ metaTitle, metaDescription, link }) => {
  const { allStrapiSocialSharing } = useStaticQuery(graphql`
    {
      allStrapiSocialSharing {
        nodes {
          locale
          buttonText
          shareTo
        }
      }
    }
  `);

  /*
   * Sets the current locale .
   */
  const { locale } = useContext(LocaleContext);

  // Find matching locale.
  const strapiSocialSharing = allStrapiSocialSharing.nodes.find(
    (node) => node.locale === locale
  );
  return (
    <SuspenseHelper fallback={<SuspenseLoader />}>
      <SharePageBlock
        buttonText={strapiSocialSharing.buttonText}
        link={link}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        noReferer
      />
    </SuspenseHelper>
  );
};

export default Share;
