import React, { createContext } from 'react';

export const LocaleContext = createContext(null);

export const appendLocale = (url, locale) => `/${locale}${url}`;

const LocaleProvider = ({ children, locale = `en`, locales = [] }) => {
  return (
    <LocaleContext.Provider value={{ locale, locales }}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
