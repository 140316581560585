import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import Link from '@components/atoms/Link';
import {
  InstagramIcon,
  FacebookIcon,
  YoutubeIcon
} from '@components/atoms/icons';

const SocialNav = ({ socialLinks, ...props }) => {
  return (
    <Box {...props}>
      <HStack gap={4} justifyContent={{ base: `center`, lg: `flex-start` }}>
        {typeof socialLinks?.title !== `undefined` &&
          socialLinks?.title !== null && (
            <Text
              fontFamily="headingSub"
              fontSize="lg"
              textTransform="uppercase"
              mb="0">
              {socialLinks.title}
            </Text>
          )}
        {typeof socialLinks?.youtubeLink !== `undefined` &&
          socialLinks?.youtubeLink !== null && (
            <Link
              href={socialLinks.youtubeLink}
              color="white"
              transition=" color .3s ease-in-out"
              _hover={{
                color: `milka.primary.dark-purple`
              }}>
              <YoutubeIcon w={8} h={8} />
            </Link>
          )}
        {typeof socialLinks?.instagramLink !== `undefined` &&
          socialLinks?.instagramLink !== null && (
            <Link
              href={socialLinks.instagramLink}
              color="white"
              transition=" color .3s ease-in-out"
              _hover={{
                color: `milka.primary.dark-purple`
              }}>
              <InstagramIcon w={8} h={8} />
            </Link>
          )}
        {typeof socialLinks?.facebookLink !== `undefined` &&
          socialLinks?.facebookLink !== null && (
            <Link
              href={socialLinks.facebookLink}
              color="white"
              transition=" color .3s ease-in-out"
              _hover={{
                color: `milka.primary.dark-purple`
              }}>
              <FacebookIcon w={8} h={8} />
            </Link>
          )}
      </HStack>
    </Box>
  );
};

export default SocialNav;
