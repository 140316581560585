import React, { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
}

const LazyLoad = ({ children, ...props }) => {
  const containerRef = useRef(null);
  useEffect(() => {
    gsap.set(containerRef.current, { y: 60, autoAlpha: 0 });
    gsap.to(containerRef.current, {
      scrollTrigger: containerRef.current,
      y: 0,
      autoAlpha: 1,
      duration: 1.2,
      delay: 0.3,
      ease: `power3.out`
    });
  }, []);
  return (
    <Box className="lazy-load-container" ref={containerRef} {...props}>
      {children}
    </Box>
  );
};

export default LazyLoad;
