import React from 'react';
import Button from '@components/atoms/Button';

const PaginationButton = ({
  value = ``,
  isActive = false,
  onClick = () => {}
}) => (
  <Button
    size="ellipsedSmall"
    textColor={isActive ? `milka.primary.dark-purple` : `white`}
    color={isActive ? `milka.primary.dark-purple` : `white`}
    bgColor={isActive ? `white` : `milka.primary.dark-purple`}
    px="0 !important"
    width={{ base: 12, md: 14 }}
    _hover={
      isActive
        ? {
            cursor: `default`
          }
        : {
            textColor: `milka.primary.dark-purple`,
            color: `milka.primary.dark-purple`,
            bg: `white`
          }
    }
    _focus={
      isActive
        ? {
            cursor: `default`
          }
        : {
            textColor: `milka.primary.dark-purple`,
            color: `milka.primary.dark-purple`,
            bg: `white`
          }
    }
    onClick={onClick}>
    {value}
  </Button>
);

export default PaginationButton;
