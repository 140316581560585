import React from 'react';
import { Icon } from '@chakra-ui/react';

const FacebookIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M21.3777 11.8233C21.3777 9.94577 20.8528 8.1076 19.8654 6.52644C18.878 4.94528 17.4694 3.68736 15.8063 2.90163C14.1432 2.1159 12.2954 1.83528 10.4816 2.09299C8.66774 2.3507 6.96394 3.13595 5.57191 4.35575C4.17988 5.57555 3.15792 7.17882 2.62705 8.9757C2.09618 10.7726 2.07864 12.6878 2.57649 14.4946C3.07435 16.3014 4.06675 17.924 5.43619 19.1704C6.80562 20.4168 8.49473 21.2348 10.3035 21.5274V14.6619H7.86935V11.8233H10.3035V9.66239C10.3035 7.19849 11.7267 5.83497 13.9197 5.83497C14.6388 5.84512 15.3562 5.90908 16.0662 6.02634V8.41848H14.853C14.6471 8.39126 14.4379 8.41186 14.2409 8.47873C14.0439 8.54561 13.864 8.65705 13.7147 8.80482C13.5653 8.95259 13.4503 9.1329 13.3781 9.33241C13.3059 9.53193 13.2784 9.74553 13.2976 9.95742V11.7994H15.9573L15.5373 14.638H13.2898V21.5035C15.5403 21.1394 17.5906 19.965 19.0729 18.1909C20.5551 16.4169 21.3723 14.1593 21.3777 11.8233Z" />
  </Icon>
);

export default FacebookIcon;
