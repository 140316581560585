import React from 'react';
import { Icon } from '@chakra-ui/react';

const ArrowLeftIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M20.1887 11.8868C20.1887 11.4014 19.7952 11.0079 19.3098 11.0079H7.43646L10.6739 7.8636C11.0308 7.517 11.0308 6.94409 10.674 6.59744C10.3315 6.26473 9.78649 6.26475 9.44402 6.59749L4 11.8868L9.44402 17.1761C9.78649 17.5088 10.3315 17.5089 10.674 17.1761C11.0308 16.8295 11.0308 16.2566 10.6739 15.91L7.43646 12.7657H19.3098C19.7952 12.7657 20.1887 12.3722 20.1887 11.8868Z" />
  </Icon>
);

export default ArrowLeftIcon;
