import React from 'react';
import { Flex } from '@chakra-ui/react';
import ShareOnSocial from 'react-share-on-social';
import Button from '@components/atoms/Button';

import favicon from '../../../static/favicon.png';

const SharePageBlock = ({ buttonText, link, metaTitle, metaDescription }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      position="relative"
      zIndex="1">
      <ShareOnSocial
        textToShare=""
        link={link}
        linkTitle={metaTitle}
        linkMetaDesc={metaDescription}
        linkFavicon={favicon}
        noReferer>
        <Button
          variant="tertiary"
          size="ellipsed"
          letterSpacing="-0.0156rem"
          textTransform="uppercase"
          fontFamily="headingSub"
          fontWeight="600"
          icon>
          {buttonText}
        </Button>
      </ShareOnSocial>
    </Flex>
  );
};

export default SharePageBlock;
