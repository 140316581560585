import React from 'react';
import { Icon } from '@chakra-ui/react';

const BurgerCrossIcon = (rest) => (
  <Icon width="15" height="14" viewBox="0 0 15 14" fill="none" {...rest}>
    <line
      x1="1.63477"
      y1="12.6674"
      x2="13.302"
      y2="1.00011"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="2.06066"
      y1="1"
      x2="13.7279"
      y2="12.6673"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </Icon>
);

export default BurgerCrossIcon;
