import React, { useCallback, useEffect, useState } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { ArrowUpIcon } from '@components/atoms/icons';

const ScrollToTop = ({ text }) => {
  const [displayButton, setDisplayButton] = useState(false);
  const [initialHeight, setInitialHeight] = useState(0);

  const handleScrollToTop = useCallback(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo({
        top: 0,
        behavior: `smooth`
      });
    }
  }, []);

  useEffect(() => {
    if (typeof document === `undefined`) {
      return () => {};
    }

    const onWindowScroll = () => {
      setDisplayButton(initialHeight > 0 && window.scrollY > initialHeight);
    };

    document.addEventListener(`scroll`, onWindowScroll);

    return () => document.removeEventListener(`scroll`, onWindowScroll);
  }, [initialHeight]);

  useEffect(() => {
    if (typeof window === `undefined`) {
      return () => {};
    }

    const onWindowResized = () => {
      const { innerHeight } = window;
      setDisplayButton(false);
      setInitialHeight(innerHeight);
    };

    window.addEventListener(`resize`, onWindowResized);

    return () => window.removeEventListener(`resize`, onWindowResized);
  }, []);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const { innerHeight } = window;
      setDisplayButton(window.scrollY > 0);
      setInitialHeight(innerHeight);
    }
  }, []);

  return (
    <Box
      pos="fixed"
      bottom={{ base: 6, md: 14 }}
      right={{ base: 6, md: 14 }}
      zIndex={displayButton ? `100` : `-1`}>
      <IconButton
        disabled={displayButton === false}
        opacity={displayButton === false ? `0` : `1`}
        transition="opacity .5s, background-color .3s"
        isRound
        variant="quaternary"
        aria-label={text}
        fontSize="20px"
        width={9}
        height={9}
        icon={<ArrowUpIcon />}
        onClick={handleScrollToTop}
        backgroundColor="milka.primary.dark-purple"
        borderColor="milka.primary.light-blue"
        _hover={{
          backgroundColor: `milka.primary.light-blue`,
          color: `milka.primary.dark-purple`
        }}
      />
    </Box>
  );
};
export default ScrollToTop;
