import React, { useReducer, useEffect } from 'react';
import parse from 'html-react-parser';
import Wrapper from '@components/atoms/Wrapper';

import {
  buildNode,
  applyInlineFormat,
  TABLE_CELL_HEADER_STATE,
  LIST_TYPE,
  HEADING_SIZES
} from '@helpers/lexicalHelpers';

import {
  Heading,
  Text,
  OrderedList,
  UnorderedList,
  ListItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer
} from '@chakra-ui/react';

import Link from '@components/atoms/Link';

const LineBreak = () => {
  return <br />;
};

const TextComponent = ({ nodeProps }) => {
  const { format, text } = nodeProps;
  return parse(applyInlineFormat(format, text));
};

const HeadingComponent = ({ children, nodeProps }) => {
  const { format = `left`, tag } = nodeProps;
  return (
    <Heading as={tag} textAlign={format} mb="8" size={HEADING_SIZES[tag]}>
      {children}
    </Heading>
  );
};

const ParagraphComponent = ({ children, nodeProps }) => {
  const { format = `left` } = nodeProps;
  return (
    <Text textAlign={format} mb="8">
      {children}
    </Text>
  );
};

const LinkComponent = ({ children, nodeProps }) => {
  const { url, target, rel } = nodeProps;
  return (
    <Link
      href={url}
      target={target}
      rel={rel}
      textDecoration="underline"
      _hover={{ color: `secondary.pink` }}>
      {children}
    </Link>
  );
};

const AutolinkComponent = ({ children, nodeProps }) => {
  return (
    <LinkComponent nodeProps={{ ...nodeProps, target: `_blank` }}>
      {children}
    </LinkComponent>
  );
};

const ListComponent = ({ children, nodeProps }) => {
  const { listType } = nodeProps;
  return listType === LIST_TYPE.NUMBER ? (
    <OrderedList mb="8">{children}</OrderedList>
  ) : (
    <UnorderedList mb="8">{children}</UnorderedList>
  );
};

const ListItemComponent = ({ children }) => {
  return <ListItem>{children}</ListItem>;
};

const TableComponent = ({ descendants, onRenderDescendant }) => {
  const hasHeader = (descendant) => {
    const containsTableHeader = descendant.descendants.some(
      (ch) => ch.headerState === TABLE_CELL_HEADER_STATE
    );

    return containsTableHeader;
  };

  return (
    <TableContainer whiteSpace="normal">
      <Table variant="simple">
        {typeof descendants !== `undefined` &&
          descendants?.map((descendant) => {
            return hasHeader(descendant) ? (
              <Thead key={descendant.id}>
                {onRenderDescendant(descendant)}
              </Thead>
            ) : (
              <Tbody key={descendant.id}>
                {onRenderDescendant(descendant)}
              </Tbody>
            );
          })}
      </Table>
    </TableContainer>
  );
};

const TableRowComponent = ({ children }) => {
  return <Tr>{children}</Tr>;
};

const TableCellComponent = ({ children, nodeProps }) => {
  const { headerState } = nodeProps;
  return headerState === TABLE_CELL_HEADER_STATE.ROW ? (
    <Th valign="top">{children}</Th>
  ) : (
    <Td valign="top">{children}</Td>
  );
};

const ComponentList = {
  linebreak: LineBreak,
  heading: HeadingComponent,
  paragraph: ParagraphComponent,
  text: TextComponent,
  link: LinkComponent,
  autolink: AutolinkComponent,
  list: ListComponent,
  listitem: ListItemComponent,
  table: TableComponent,
  tablerow: TableRowComponent,
  tablecell: TableCellComponent
};

const lexicalNodeRenderer = (node, _depth = 10) => {
  if (_depth < 1) {
    throw new Error(`Node renderer depth limit exceeded.`);
  }

  const { id, type, descendants } = node;

  const TagName = ComponentList[String(type)];

  if (typeof TagName === `undefined`) {
    return (
      <div color="red">
        Component <b>{type}</b> not found
      </div>
    );
  }

  if (
    typeof descendants !== `undefined` &&
    descendants !== null &&
    descendants.length > 0
  ) {
    return type === `table` ? (
      <TagName
        key={id}
        nodeProps={node}
        descendants={descendants}
        onRenderDescendant={
          (descendant) => lexicalNodeRenderer(descendant, _depth - 1)
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />
    ) : (
      <TagName key={id} nodeProps={node}>
        {descendants?.map((descendant) => {
          return lexicalNodeRenderer(descendant, _depth - 1);
        })}
      </TagName>
    );
  }

  return <TagName key={id} nodeProps={node} />;
};

const lexicalNodeReducer = (state, actionData) => {
  if (typeof actionData === `undefined` || actionData === null) {
    return [];
  }

  const next = actionData.children.reduce((acc, current, index) => {
    return [...acc, buildNode({ index, ...current })];
  }, []);

  return next;
};

const LexicalRichText = ({ data, maxWidth, paddingSides, ...props }) => {
  const {
    richText: {
      data: { richText: jsonContent }
    }
  } = data;

  const { root } = JSON.parse(jsonContent || `{}`);
  const [state, dispatch] = useReducer(lexicalNodeReducer, []);

  useEffect(() => {
    dispatch(root);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper
      maxWidth={maxWidth || `61.625rem`}
      px={paddingSides || { base: 6, md: 14 }}
      {...props}>
      {state.map((node) => {
        return lexicalNodeRenderer(node);
      })}
    </Wrapper>
  );
};

export default LexicalRichText;
