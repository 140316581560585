import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

const TwoLineHeader = ({
  lineOne,
  lineTwo,
  headingType,
  inHero = false,
  ...rest
}) => {
  return (
    <>
      {lineOne || lineTwo ? (
        <Heading
          as={headingType || `h2`}
          position="relative"
          fontSize={
            inHero
              ? { base: `4xl`, lg: `5xl`, xl: `6xl` }
              : { base: `4xl`, lg: `8xl` }
          }
          mb="0"
          textTransform="uppercase"
          textAlign="center"
          {...rest}>
          <Box
            as="span"
            display="block"
            fontFamily="headingScript"
            fontSize={
              inHero
                ? { base: `6xl`, lg: `7xl`, xl: `5.4375rem` }
                : { base: `6xl`, lg: `9xl` }
            }
            zIndex="2"
            textTransform="capitalize"
            position="relative"
            lineHeight={
              inHero ? 1 : { base: `3.5rem`, md: `3.7rem`, lg: `8rem` }
            }>
            {lineOne}
          </Box>
          {lineTwo && (
            <Box
              as="span"
              display="block"
              mt={inHero ? -6 : { base: `-1.2rem`, md: `-1rem`, lg: `-2.5rem` }}
              color="milka.primary.light-blue">
              {lineTwo}
            </Box>
          )}
        </Heading>
      ) : (
        <></>
      )}
    </>
  );
};

export default TwoLineHeader;
