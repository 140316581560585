import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext
} from 'react';
import { Link as GatsbyLink, graphql, useStaticQuery } from 'gatsby';
import {
  Flex,
  Text,
  Box,
  AspectRatio,
  Heading,
  HStack
} from '@chakra-ui/react';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import { ButtonLink } from '@components/atoms/Button';
import Image from '@components/atoms/Image';
import Pagination from '@components/molecules/Pagination';
import scrollToPromise from '@helpers/scrollToPromise';
import dataLayerPush from '@helpers/dataLayerPush';
import { LocaleContext } from '@helpers/LocaleProvider';
import LazyLoad from '../atoms/LazyLoad';

/*
 * Results will be grouped into pages.
 */
const sortAndFilterResults = (results, perPage) => {
  // Group results into pages.
  const numberOfPages = Math.ceil(results.length / perPage);
  return Array.from({ length: numberOfPages }).map((_, i) => {
    const skip = i * perPage;
    return results.slice(skip, skip + perPage);
  });
};

const ArticleListing = ({
  staticEntries = [],
  perPage = 9,
  ctaText,
  resultsText,
  noResultsText,
  ...props
}) => {
  /*
   * Sets the current locale .
   */
  const { locale } = useContext(LocaleContext);

  /*
   * Query allStrapiProductListing to get slug of product listing page.
   */
  const { allStrapiArticleListing } = useStaticQuery(graphql`
    {
      allStrapiArticleListing {
        nodes {
          locale
          slug
        }
      }
    }
  `);

  /*
   * Find matching locale and set the base path to constuct URL.
   */
  const strapiArticleListing = allStrapiArticleListing.nodes.find(
    (node) => node.locale === locale
  );
  const basePath = `${locale}/${strapiArticleListing.slug}`;

  /*
   * Container we reference to scroll to top of results.
   */
  const containerRef = useRef(null);

  const [pagedResults, setPagedResults] = useState([]);
  const [page, setPage] = useState(1);
  const [currentResultSet, setCurrentResultSet] = useState([]);
  const [paginationItems, setPaginationItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [numPages, setNumPages] = useState(0);

  /*
   * Updates the active page when the user clicks pagination.
   */
  const changePage = useCallback(
    async (pageNum) => {
      const params = new URLSearchParams(window.location.search);
      if (pageNum === 1) {
        params.delete(`page`);
      } else {
        params.set(`page`, pageNum);
      }
      const queryString = params.toString();

      // Turn on loading state.
      setIsLoading(true);

      // Set URL.
      const { origin, pathname } = window.location;
      window.history.pushState(
        ``,
        ``,
        `${origin}${pathname}${queryString ? `?${queryString}` : ``}`
      );

      // Scroll to top of listing.
      await scrollToPromise(containerRef.current);

      // Update page.
      setPage(pageNum);

      // Update result set.
      if (pageNum > 0 && pagedResults) {
        setCurrentResultSet(pagedResults[pageNum - 1]);
      }

      // Turn off loading state.
      setIsLoading(false);
    },
    [pagedResults]
  );

  /*
   * Refreshes results after update operation
   */
  const refreshResults = useCallback(
    async (payload) => {
      const newPagedResults = sortAndFilterResults(staticEntries, perPage);
      const newPageCount = newPagedResults?.length || 0;
      const newPaginationItems = Array.from(
        { length: newPageCount },
        (_, i) => i + 1
      );

      setPage(payload.page);
      setPagedResults(newPagedResults);
      setPaginationItems(newPaginationItems);
      setNumPages(newPageCount);

      if (newPageCount > 0 && newPagedResults) {
        setCurrentResultSet(newPagedResults[payload.page - 1]);
      }

      dataLayerPush({
        event: `article listing`,
        interaction: {
          // search_term: payload.query,
          results_returned: newPagedResults?.length || 0
        }
      });
    },
    [perPage, staticEntries]
  );

  /*
   * On page load set initial results based on URL parameters.
   */
  useEffect(() => {
    const setInitialResults = async () => {
      const params = new URLSearchParams(window.location.search);
      const newPage = parseInt(params.get(`page`), 10) || 1;

      // Turn on loading state.
      setIsLoading(true);

      // Refresh result states
      await refreshResults({
        page: newPage
      });

      // Turn off loading state.
      setIsLoading(false);
    };

    setInitialResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // defaultFilterValues, filters, staticEntries all cause an infinite loop

  return (
    <Section {...props}>
      <Wrapper maxWidth="75.75rem">
        {pagedResults !== null && pagedResults.length > 0 ? (
          <Box
            ref={containerRef}
            opacity={isLoading ? 0.5 : null}
            pointerEvents={isLoading ? `none` : null}>
            <HStack
              flexWrap="wrap"
              justifyContent={{ md: `space-between` }}
              columnGap="16">
              {currentResultSet?.map(({ entry }, index) => (
                <Box
                  key={entry.slug}
                  width={{ base: `100%`, md: `calc(50% - 2rem)` }}
                  mb={{ base: 6, md: 28 }}
                  mt={{ base: null, md: index % 2 !== 0 ? `28` : 0 }}>
                  <LazyLoad>
                    <Box className="button-hover">
                      <Box as={GatsbyLink} to={`/${basePath}/${entry.slug}`}>
                        <AspectRatio
                          ratio={4 / 3}
                          overflow="hidden"
                          display="block">
                          <Image image={entry.thumbnail} />
                        </AspectRatio>
                        <Heading
                          as="h2"
                          fontSize={{ base: `lg`, md: `3xl` }}
                          color="white"
                          pt={{ base: 8, md: 10 }}
                          mb={{ base: 6, md: 8 }}
                          textTransform="uppercase"
                          fontFamily="headingSub"
                          letterSpacing="-0.0156rem">
                          {entry.title}
                        </Heading>
                      </Box>
                      <ButtonLink
                        href={`/${basePath}/${entry.slug}`}
                        variant="tertiary"
                        size="ellipsed"
                        icon
                        onClick={() => {
                          dataLayerPush({
                            event: `cta_click`,
                            interaction: {
                              click_text: entry.title,
                              link_url: `/${basePath}/${entry.slug}`,
                              cta_type: `news_card`
                            }
                          });
                        }}>
                        {ctaText}
                      </ButtonLink>
                    </Box>
                  </LazyLoad>
                </Box>
              ))}
              {(typeof currentResultSet === `undefined` ||
                pagedResults.length === 0) &&
                noResultsText && <Text>{noResultsText}</Text>}
            </HStack>
            {numPages > 1 && (
              <Flex justifyContent="center" mt={{ base: 8, md: 20 }}>
                <Pagination
                  itemPadding="2"
                  displayAs="buttons"
                  paginationItems={paginationItems}
                  divideAt={perPage}
                  activePage={page}
                  numPages={numPages}
                  onPreviousSet={() => {
                    const prevPage = page - 1;
                    if (prevPage >= 1 && prevPage <= numPages) {
                      changePage(prevPage);
                    }
                  }}
                  onNextSet={() => {
                    const nextPage = page + 1;
                    if (nextPage >= 1 && nextPage <= numPages) {
                      changePage(nextPage);
                    }
                  }}
                  onItemClicked={(itemNumber) => {
                    changePage(itemNumber);
                  }}
                />
              </Flex>
            )}
          </Box>
        ) : (
          <Text>{noResultsText}</Text>
        )}
      </Wrapper>
    </Section>
  );
};

export default ArticleListing;
