import React, { useContext } from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import ProductCard from '@components/molecules/ProductCard';
import { LocaleContext } from '@helpers/LocaleProvider';
import LazyLoad from '@components/atoms/LazyLoad';

const ProductListing = ({ products, parentSlug }) => {
  const { locale } = useContext(LocaleContext);
  return (
    <Section>
      <Wrapper>
        <Grid templateColumns="repeat(12, 1fr)" gap={5} w="100%">
          {products.map((product) => (
            <GridItem key={product.slug} colSpan={{ base: 12, md: 6, lg: 4 }}>
              <LazyLoad>
                <ProductCard
                  basePath={`/${locale}/${parentSlug}`}
                  product={product}
                  yMargin={false}
                />
              </LazyLoad>
            </GridItem>
          ))}
        </Grid>
      </Wrapper>
    </Section>
  );
};

export default ProductListing;
