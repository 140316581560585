import React, { useEffect, useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Image as ChakraImage } from '@chakra-ui/react';

const Image = ({ image, ...rest }) => {
  const [componentReady, setComponentReady] = useState(false);

  useEffect(() => {
    setComponentReady(true);
  }, []);

  if (
    componentReady === false ||
    typeof image?.localFile === `undefined` ||
    !image.localFile
  ) {
    return null;
  }

  const alt = image.alternativeText || ``;

  // Attempt to use processed image from gatsby plugin.
  if (image.localFile.childImageSharp !== null) {
    return (
      <GatsbyImage
        image={image.localFile.childImageSharp.gatsbyImageData}
        alt={alt}
        {...rest}
        loading="lazy"
      />
    );
  }

  // Fallback to original source for formats that couldn't be processed eg. GIF, SVG.
  return (
    <ChakraImage src={image.localFile.url} alt={alt} {...rest} loading="lazy" />
  );
};

export default Image;
