import React from 'react';
import { Icon } from '@chakra-ui/react';

const AddIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 11.6998C3 11.2856 3.33579 10.9498 3.75 10.9498L20.25 10.9498C20.6642 10.9498 21 11.2856 21 11.6998C21 12.114 20.6642 12.4498 20.25 12.4498L3.75 12.4498C3.33579 12.4498 3 12.114 3 11.6998Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3002 3C12.7144 3 13.0502 3.33579 13.0502 3.75L13.0502 20.25C13.0502 20.6642 12.7144 21 12.3002 21C11.886 21 11.5502 20.6642 11.5502 20.25L11.5502 3.75C11.5502 3.33579 11.886 3 12.3002 3Z"
    />
  </Icon>
);

export default AddIcon;
